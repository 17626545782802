import styled from "styled-components";

const SwitchContainer = styled("div")`
& .image-lang{
  width: 32px;
  height: 32px;
}
`;
export { SwitchContainer };


