import React, { useEffect } from "react";
import { IntlActions, withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import { HeaderContainer } from "./styled";
import { Button } from "antd";
import {
  SideBar,
  Header,
  Breadcrumbs,
  Footer,
  SettingLayout,
} from "@admin/components/admin";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ButtonSideBar from "@images/icon/btn-show-sidebar.png";

const HeaderMobile = (props) => {
  const showSideBar = () => {

  };
  return (
    props.widthWindow <=992 ?(
      <HeaderContainer className="header-mobile">
      <div className="left">
        {" "}
        <div>
          <Button onClick={() => showSideBar()}>
            <ArrowLeftOutlined />
          </Button>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div className="right">
        <Button>
          <a
            href="#"
            className="waves-effect waves-themed"
            data-action="toggle"
            data-class="mobile-nav-on"
          >
            <img src={ButtonSideBar}></img>
          </a>
        </Button>
      </div>
    </HeaderContainer>
    ) : null

  );
};
export default connect((state) => {
  return {
    locale: state.dataIntl.locale,
    widthWindow : state.auth.widthWindow,
  };
}, {})(withTranslate(HeaderMobile));
