module.exports = {
  key: {
    SUMARY_BY_MONTH: "SUMARY_BY_MONTH",
    SUMARY_BY_YEAR: "SUMARY_BY_YEAR",
  },
  text: {
    internal_server_error: "Xảy ra lỗi, vui lòng thử lại sau",
    user: {
      success_login: "Đăng nhập thành công",
      login_error: "Đăng nhập không thành công",
    },
  },
  api: {
    user: {
      login: "/api/visitor/v1/auth/login",
      detail: "/api/user/detail",
    },
    file: {
      upload: "/api/visitor/v1/check-in/anh-dai-dien",
    },
    login: "/api/visitor/v1/auth/login",
    users: "/api/visitor/v1/users",
    quocGia: "/api/visitor/v1/dm-quoc-gia",
    tinhTP: "/api/visitor/v1/dm-tinh-thanh-pho",
    xaPhuong: "/api/visitor/v1/dm-xa-phuong",
    quanHuyen: "/api/visitor/v1/dm-quan-huyen",
    ttHanhChinh: "/api/visitor/v1/tt-hanh-chinh",
    checkIn: "/api/visitor/v1/check-in",
    khuVuc: "/api/visitor/v1/dm-khu-vuc",
    doiTuong: "/api/visitor/v1/dm-doi-tuong",
    donVi: "/api/visitor/v1/dm-don-vi",
    ngheNghiep: "/api/visitor/v1/dm-nghe-nghiep",
    report: "/api/visitor/v1/check-in/tong-hop/excel",
    roles: "/api/visitor/v1/roles",
    post: "/api/visitor/v1/dm-cau-hoi",
    bocauhoi: "/api/visitor/v1/dm-bo-cau-hoi",
    thietlap: "/api/visitor/v1/thiet-lap",
    khoa: "/api/visitor/v1/dm-khoa",
    khaiBaoYTe: "/api/visitor/v1/khai-bao-y-te",
    sendOTP: "/api/visitor/v1/nb-xac-thuc",
    dichTe: "/api/visitor/v1/khai-bao-dich-te",
    enums: "/api/visitor/v1/utils/enums",
    ai_covid: "/api/visitor/v1/ai-covid",
  },
};
