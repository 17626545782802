const reducer = (state = {}, action) => {
    let newState = { ...state };
    switch (action.type) {
      case "ENUM-UPDATE-DATA":
        newState = { ...state, ...(action.data || {}) };
        return newState;
      case "ENUM_CLEAR_DATA":
        return {};
      default:
        return state;
    }
  };
  export default reducer;