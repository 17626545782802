import styled from "styled-components";

const SwitchContainer = styled("div")`
  width: 80px;
  height: 30px;
  button {
    background: #2574ff;
    width: 80px;
    height: 30px;
  }
  .ant-switch::after {
    content: attr(data-content);
    -webkit-box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
    box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
    width: 30px;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-top: -1px;
  }
`;
export { SwitchContainer };
