import React, { useEffect, useState } from "react";
import { Popover, Switch, Tooltip } from "antd";
import { SwitchContainer } from "./styled";
import { withTranslate } from 'react-redux-multilingual';
import { connect } from "react-redux";
const SwitchCustom = ({ translate, ...props }) => {
  const { firstName, lastName, onChangeBack } = props;
  const [showLanguage, setShowLanguage] = useState(false);
  const onChange = (e) => {
    const select = document.querySelector("#switch-custom");
    if (e) {
      select.setAttribute("data-content", lastName);
      //onChangeBack("en");f
      setTimeout(() => onChangeBack("vi"), 500);
    } else {
      select.setAttribute("data-content", firstName);
      setTimeout(() => onChangeBack("en"), 500);
    }
  };
  const contentLang = (
    <div>
      <div
        className="item-action"
        style={{ cursor: "pointer" }}
        onClick={() => {
          onChangeBack("vi");
          setShowLanguage(false);
        }}
      >
        <span>Tiếng Việt - VI</span>
      </div>
      <div
        className="item-action"
        style={{ cursor: "pointer" }}
        onClick={() => {
          onChangeBack("en");
          setShowLanguage(false);
        }}
      >
        <span>English - EN</span>
      </div>
      <div
        className="item-action"
        style={{ cursor: "pointer" }}
        onClick={() => {
          onChangeBack("ko");
          setShowLanguage(false);
        }}
      >
        <span>한국어 - KO</span>
      </div>
    </div>
  );

  const onOpenChange = (fn) => (isOpen) => {
    fn(isOpen);
  };


  return (
    <SwitchContainer>
      <Tooltip title={translate("locale")} className="header-icon">
        <Popover
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px !important",
          }}
          placement="bottomRight"
          content={contentLang}
          trigger="click"
          open={showLanguage}
          onOpenChange={onOpenChange(setShowLanguage)}
        >
          <img
            src={
              "/img/demo/avatars/avatar-admin.png"
            }
            className="image-lang"
          />        
          </Popover>
      </Tooltip>
    </SwitchContainer>
  );
};
export default connect(
  (state) => {
    return {
      locale: state.dataIntl.locale
    };
  },
  {}
)(withTranslate(SwitchCustom));
