import style from "styled-components";
const Ol = style.ol`
  // background: linear-gradient(41.51deg,#fe8803 -0.1%,#fed603 101.9%);
  margin-bottom: 0px;
  background: white;
  li{
    color: black !important;
    a{
      color: black !important;
    }
  }
  .breadcrumb-item{
    font-weight: 800;
    font-size: 16px;
    line-height: 10px;
    color: #193D6B;
  }
`;
export { Ol };
