export default {
  en: {
    ngonNgu: "English",
    locale: "en",
    messages: {
      nhapdonvitructhuoc: "Enter affiliated units",
      donvitructhuoc: "Affiliated units",
      chupanhcanhan: "Take personal photos",
      vuilongchonphanloaikhach: "Please select Guest Category!",
      ngaycheckin: "Check-in date",
      chonthongtinnguoibenh: "Select Patient Information",
      nhapvaotukhoa: "Enter keywords",
      banchuanhanduocma: "Haven&#39;t received the code yet?",
      vuilongnhapOTP:
        "Please enter the OTP code the system sends via text message to your phone number",
      luuyOTP:
        "(Note: The system will send an OTP message to your phone number)",
      vuilongchoncoquanden: "Please select the destination agency!",
      vuilongchonngayden: "Please select arrival date!",
      nhaptenphieukhaithacthongtin:
        "Enter the name of the information extraction form",
      front_side: "Front",
      rear_side: "Backside",
      label_pager: "Print stamps",
      receipt_pager: "Paper cut",
      co: "Have",
      khong: "Are not",
      hovaten255: "Full name is too long, requires &lt;= 255 characters",
      sdtnguoithan: "Phone number of relatives",
      khong_ton_tai_bang_hoi:
        "Question set does not exist. Please contact with the system management!",
      in_phieu: "Print coupons",
      the_khach: "GUEST CARD",
      registration_history: "Registration history",
      update_data: "Edit information",
      scan_qr_code: "Scan Qrcode Guest code",
      tim_theo_ma_khach: "SEARCH BY CUSTOMER CODE, ID, Phone number",
      take_photo: "Take a photo",
      your_photo: "YOUR PICTURE",
      khai_bao_khong_thanh_cong: "Information declaration failed",
      khai_bao_thanh_cong: "Information declared successfully",
      vui_long_nhap_so_dien_thoai: "Please enter the phone number",
      nhapthongtinkhoadieutri: "Enter treatment department information",
      hoanthanh: "Complete",
      chuphinh: "Take a photo",
      ngayden: "The day arrives",
      khuvucden: "Arrival area",
      chonkhuvucden: "Select arrival area",
      choncoquanden: "Select destination agency",
      coquanden: "The agency arrives",
      vuilongchonthongtinphiaduoi: "Please select information below!",
      not_registered_yet: "Unregistered",
      registered: "Registered",
      checked_out: "Have left",
      dungdinhdangngaydangky:
        "Please enter the correct registration date format!",
      vuilongnhapngaydangky: "Please enter registration date!",
      general_information: "General information",
      who_are_you: "Who are you?",
      register_info: "REGISTER CUSTOMER INFORMATION",
      inphanloaikhach: "Print Guest classification",
      tenphieukhaithacthongtin: "Name of Information exploitation form",
      saochepthongtin: "Copy information",
      vuilongnhapgoiythongtinlienhe:
        "Please enter contact information suggestions!",
      nhapgoiythongtinlienhe: "Enter suggested contact information",
      goiythongtinlienhe: "Suggested contact information",
      timtheothongtinlienquan: "Search by related information",
      thongtinlienquan: "Related information",
      timtheomadoituong: "Search by object code",
      timtheotendoituong: "Search by object name",
      madoituong: "Object code",
      tendoituong: "Object name",
      tendoituongtontai: "The object name already exists on the system",
      madoituongtontai: "The object code already exists on the system",
      bancomuonxoadoituong: "Are you sure you want to delete the object",
      xoadoituongthanhcong: "Object deleted successfully!",
      bandaxoadoituong: "You have deleted the object",
      thanhcong: "success",
      xoadoituongthatbat: "Object deletion failed!",
      themmoidoituongthanhcong: "New object added successfully!",
      themmoidoituongthatbai: "Adding new object failed!",
      capnhatthongtindoituongthanhcong:
        "Updated object information successfully!",
      capnhatdoituongthatbai: "Object update failed!",
      suadoituong: "Edit object",
      nhaptendoituong: "Enter the object name",
      nhapmadoituong: "Enter the object code",
      vuilongnhaptendoituong: "Please enter object name!",
      vuilongnhapmadoituong: "Please enter object code!",
      themmoidoituong: "Add new object",
      capnhatdoituong: "Update object",
      quanlydoituong: "Manage object lists",
      danhsachdoituong: "List of objects",
      tiengtrung: "Chinese",
      tienghan: "Korean",
      tiengnhat: "Japanese",
      caidat: "Setting",
      ngonngu: "Language",
      timtheomakhuvuc: "Search by area code",
      timtheotenkhuvuc: "Search by area name",
      makhuvuc: "Area code",
      tenkhuvuc: "Area name",
      tenkhuvuctontai: "The region name already exists on the system",
      makhuvuctontai: "The area code already exists on the system",
      bancomuonxoakhuvuc: "Do you want to delete this area?",
      xoakhuvucthanhcong: "Area deletion successful!",
      xoakhuvucthatbat: "Region deletion failed!",
      themmoikhuvucthanhcong: "Added new area successfully!",
      themmoikhuvucthatbai: "Added new failure area!",
      capnhatkhuvucthanhcong: "Region update successful!",
      capnhatkhuvucthatbai: "Region update failed!",
      suakhuvuc: "Edit area",
      nhaptenkhuvuc: "Enter the region name",
      nhapmakhuvuc: "Enter the area code",
      vuilongnhaptenkhuvuc: "Please enter a region name!",
      vuilongnhapmakhuvuc: "Please enter the area code!",
      themmoikhuvuc: "Add new area",
      capnhatkhuvuc: "Update area",
      quanlykhuvuc: "Manage area lists",
      danhsachkhuvuc: "Area list",
      tendonvitontai: "The unit name already exists on the system",
      madonvitontai: "The unit code already exists on the system",
      error503: "The server is temporarily down",
      bancomuonxoadonvi: "Do you want to delete this unit?",
      xoadonvithanhcong: "Successfully deleted unit!",
      xoadonvithatbat: "Unit deletion failed!",
      themmoidonvithanhcong: "New unit added successfully!",
      themmoidonvithatbai: "Adding new units failed!",
      capnhatdonvithanhcong: "Unit update successful!",
      capnhatdonvithatbai: "Unit update failed!",
      suadonvi: "Edit units",
      nhaptendonvi: "Enter the unit name",
      nhapmadonvi: "Enter the unit code",
      vuilongnhaptendonvi: "Please enter unit name!",
      vuilongnhapmadonvi: "Please enter unit code!",
      themmoidonvi: "Add new units",
      capnhatdonvi: "Update units",
      quanlydonvi: "Manage unit lists",
      logodonvi: "Unit logo",
      timtheomadonvi: "Search by unit code",
      timtheotendonvi: "Search by unit name",
      tendonvi: "Unit name",
      madonvi: "Unit code",
      capnhatthietlapthanhcong: "Update settings successfully!",
      capnhatthietlapthatbai: "Settings update failed!",
      yeucaunhapsdt: "Required to enter Phone Number",
      manhinhnhapsdt: "Use the SDT Entry Screen",
      yeucaubanghoi: "Request Questionnaire",
      yeucauchuphinh: "Request a photo",
      thietlapchung: "General settings",
      danhsachdonvi: "List of units",
      bancomuonxoacauhoi: "Do you want this question?",
      xoacauhoithanhcong: "Question deleted successfully!",
      xoacauhoithatbat: "Question deletion failed!",
      tuychon: "Option",
      bancomuonxoabocauhoi: "Are you sure you want to delete the question set?",
      xoabocauhoithanhcong: "Successfully deleted question set!",
      xoabocauhoithatbat: "Delete question set failed!",
      taocauhoi: "Create questions",
      themtuychon: "More options",
      macdinh: "Default",
      vuilongnhapnoidungchocotnay: "Please enter content for this column!",
      vuilongnhapnoidungchohang: "Please enter content for this row!",
      cautraloidai: "Long answer",
      batbuoc: "Obligatory",
      nhieucautraloi: "Many answers",
      themtuychonkhac: "Add Other option",
      vuilongnhaptenchotuychon: "Please enter a name for this option!",
      vuilongnhapnoidungoiy: "Please enter suggested content!",
      goiy: "Suggest",
      vuilongnhapnoidungcauhoi: "Please enter question content!",
      nhapthongtinbocauhoi: "Enter question set information",
      vuilongnhapcauhoi: "Enter the name of the question set",
      vuilongnhaptencauhoi: "Please enter the question set name!",
      nhapmacauhoi: "Enter the question set code",
      vuilongnhapmacauhoi: "Please enter the question set code!",
      tencauhoi: "Question name",
      macauhoi: "Question code",
      hieuluc: "Effect",
      chondoituong: "Select object",
      doituong: "Object",
      timbocauhoi: "Search by question set",
      mabocauhoi: "Question set code",
      tenbocauhoi: "Name of the question set",
      timmabocauhoi: "Search by question set code",
      themcot: "More Collum",
      themhang: "Add goods",
      binhthuong: "Normal",
      cot: "Column",
      hang: "Row",
      khac: "Other",
      batthuong: "ABNORMAL",
      intokhai: "Print the declaration",
      xemqr: "See QRcode",
      quaylai: "Come back",
      mattruoc: "Front",
      matsau: "Backside",
      khaibaoyte: "HEALTH DECLARATION",
      khaibaoytetunguyen: "Voluntary medical declaration",
      diachi: "Address",
      cccd: "CMT/CCCD",
      thongtinkhachhang: "Customer information",
      chitietkhachdendonvi: "Details of visitors to the unit",
      baocaodetailhref: "/how-high/chi-tiet",
      timsdt: "Search by phone number",
      timcmt: "Search by ID/CCCD/Passport number",
      timmakhach: "Search by customer code",
      timtenkhach: "Search by customer name",
      chitiet: "See details",
      xoa: "Erase",
      capnhat: "Update",
      themmoibocauhoithanhcong: "Added a new set of questions successfully!",
      themmoibocauhoithatbai: "Added a new set of failed questions!",
      capnhatbocauhoithanhcong: "Updated the question set successfully!",
      capnhatbocauhoithatbai: "Update question set failed!",
      themmoicauhoithanhcong: "Added new question successfully!",
      themmoicauhoithatbai: "Add new failed question!",
      capnhatcauhoithanhcong: "Question updated successfully!",
      capnhatcauhoithatbai: "Question update failed!",
      themmoibocauhoi: "Add a new set of questions",
      capnhapbocauhoi: "Update question set",
      khaibaodanhsachcauhoi: "Declare a list of question sets",
      danhsachcauhoi: "List of question sets",
      bocauhoi: "Set of questions",
      suabocauhoi: "Edit question set",
      sdtnguoibaoho: "Protector&#39;s phone number",
      taikhoantontai: "Account already exists",
      timkiemtinhnang: "Search features",
      xayraloi: "An error occurred, please try again later!",
      themmoitaikhoanthatbai: "Adding new account failed",
      capnhattaikhoanthatbai: "Account update failed",
      trong: "in",
      thaydoimatkhauthatbai: "You have failed to change your password!",
      thaydoimatkhauthanhcong: "You have successfully changed your password!",
      khoathatbai: "Your account has failed!",
      mokhoathatbai: "You have failed to unlock your account!",
      khoathanhcong: "You have successfully locked your account!",
      mokhoathanhcong: "You have successfully unlocked your account!",
      lammoimatkhauthatbai: "You have failed to refresh your password",
      lammoimatkhau: "You have successfully renewed your password",
      bancomuonreset: "Are you sure you want to reset your password?",
      no: "Cancel",
      oki: "Confirm",
      xacnhan: "Confirm",
      banmuonmokhoa: "Are you sure you want to unlock this account?",
      banmuonkhoa: "Are you sure you want to block this account?",
      capnhattaikhoanthanhcong: "Account updated successfully",
      themmoitaikhoanthanhcong: "New account created successfully",
      themthanhcong: "More success",
      capnhatthanhcong: "Update successful",
      capnhatthatbai: "Update failed",
      themmoithatbai: "New add failed",
      thongbaodetail: "No matching results were found",
      taikhoanvamatkhau: "Please enter your account and password!",
      checksdt: "Please enter the correct phone number format!",
      checkdate: "Date of birth cannot be greater than current date",
      checkcancuoc: "Please enter the correct ID/CCCD/Passport number format!",
      xacnhanmatkhau: "Confirm password",
      matkhaumoi: "A new password",
      nhapmatkhaucu: "Enter old password",
      vuilongnhapmatkhaucu: "Please enter old password!",
      nhaplaimatkhaumoi: "Please re-enter new password!",
      matkhaucu: "old password",
      luu: "Save",
      thaydoimatkhau: "Change the password",
      xacnhanmatkhaukhongtrung: "Confirm new password does not match!",
      huy: "Cancel",
      taomoi: "Create new",
      luuthaydoi: "Save changes",
      matkhau: "Password",
      nhapmatkhau: "Enter password",
      themmoitaikhoan: "Add new account",
      capnhattaikhoan: "Update account information",
      checkmatkhau:
        "Password requires a minimum of 8 characters; includes uppercase letters, lowercase letters, special characters and numbers!",
      vuilongnhapmatkhaumoi: "Please enter new password!",
      checktaikhoan:
        "Account name between 6 - 20 characters, excluding spaces and special characters!",
      vuilongnhaptendangnhap: "Please enter your username!",
      mokhoataikhoan: "Unlock account",
      khoataikhoan: "Lock account",
      resetmatkhau: "Refresh password",
      suataikhoan: "Edit account",
      tienich: "Utilities",
      ghichu: "Note",
      nhapghichu: "Enter notes",
      chonngaydangnhapgannhat: "Select the most recent login date",
      dangnhapgannhat: "Most recent login",
      nhapngaytao: "Enter creation date",
      ngaytao: "Date created",
      chontrangthai: "Select status",
      trangthai: "Status",
      chonquyen: "Select permissions",
      quyen: "Permission",
      vuilongchonquyen: "Please choose the right!",
      timtheoten: "Search by username",
      chonkhuvuc: "Select region",
      khuvuc: "Area",
      vuilongchonkhuvuc: "Please select a region!",
      tatca: "All",
      tendangnhap: "user name",
      nhaptendangnhap: "Enter your login name",
      loctheo: "FILTER BY",
      themmoi: "Add new",
      toanmanhinh: "Full screen",
      datlaibocuc: "Reset layout",
      catdat: "Setting",
      in: "print",
      doimatkhau: "Change Password",
      dangxuat: "Log out",
      dangnhap: "Log in",
      tienganh: "English",
      tiengviet: "Vietnamese",
      tbchuyendoingonngu: "Successfully changed language!",
      english: "English - EN",
      vietnam: "Vietnamese - VI",
      thaydoingonngu: "Change language",
      tieuchichonbaocao: "Select report printing criteria",
      baocaothongke: "Statistical report of Visitors to the Unit",
      trangdau: "first page",
      trangcuoi: "Last page",
      sohanghienthi: "Number of rows displayed",
      phanloai: "Classify",
      tenkhach: "Customer name",
      makhach: "Customer&#39;s code",
      ngaydanhky: "Registration Date",
      stt: "No",
      excel: "Export excel",
      xemdanhsach: "See List",
      donvi: "Unit",
      vuilongchondonvi: "Please select a unit!",
      chondonvi: "Select unit",
      nguoinhanguoibenh: "Family members of the sick",
      nguoibenh: "Patient",
      khach: "Guest",
      checkout: "Checkout area",
      checkin: "Check-in area",
      choncheckout: "Select the checkout area",
      choncheckin: "Select check-in area",
      denngay: "To date",
      tungay: "Since",
      danhsachkhachdendonvi: "List of visitors to the Unit",
      baocao: "Report",
      baocaohref: "/bao-cao",
      luuqr: "Save your QR code",
      thongtinqr: "Your QR information",
      vuilongnhapsonha: "Please enter the full House Number/Street/Street...!",
      vuilongchonxa: "Please select Commune/Ward!",
      vuilongchonhuyen: "Please select District!",
      vuilongchonTP: "Please select Province/City!",
      dungdinhdangngay: "Please enter the correct date format!",
      vuilongnhapngaysinh: "Please enter Date of Birth!",
      vuilongnhaphovaten: "Please Enter Full Name!",
      quanlytaikhoan: "Account management",
      danhsachtaikhoan: "List of accounts",
      thongtinhanhchinh: "Administrative information",
      thongtinhanhchinhherf: "/thong-tin-hanh-chinh",
      thongtincanhan: "Personal information",
      hovaten: "First and last name",
      nhaphovaten: "Enter your first and last name",
      sdt: "Phone number",
      nhapsdt: "Enter your phone number",
      nhapsdtnguoibaoho: "Applies to people &lt;18 years old and the elderly",
      cmt: "ID/CCCD/Passport number",
      nhapcmt: "Enter ID/CCCD/Passport number",
      ngaysinh: "Date of birth",
      nhapngaysinh: "Enter date, month, year (Example: 310190)",
      nhapngay: "Enter date in dd/mm/yyyy format",
      gioitinh: "Sex",
      chongioitinh: "Choose gender",
      nam: "Male",
      nu: "Female",
      quoctich: "Nationality",
      chonquoctich: "Choose nationality",
      nghenghiep: "Job",
      chonnghenghiep: "Choose a career",
      nguoibaoho: "Protector",
      nhaphovatennguoibaoho:
        "Applies to people &lt;18 years old and the elderly",
      diachivn: "Address in Vietnam",
      thongTinThem: "Additional information",
      chucVu: "Position",
      nhapChucVu: "Enter position",
      donVi: "Entity",
      nhapDonVi: "Enter entity",
      soGhe: "seat number",
      nhapSoGhe: "Enter seat number",
      trinhDoHocVan: "Education level",
      nhapTrinhDoHocVan: "Education level",
      loaiHinh: "Category",
      nhapLoaiHinh: "Enter category",
      tp: "Province / City",
      chontp: "Select Province/City",
      huyen: "District",
      chonhuyen: "Select district/district",
      xa: "Wards",
      chonxa: "Select commune/ward",
      sonha: "House number/Road/Street...",
      nhapsonha: "Enter House Number/ Street/ Street...",
      qr: "Generate QR codes",
      lammoi: "Refresh",
      timkiembenhnhan: "Search for patients",
      boqua: "Skip",
      tieptuc: "Continue",
      maotp: "Please enter OTP code!",
      guilaimaotp: "Resend OTP code",
      taolinkcheckin: "Create links",
      taolinkbocauhoi: "Create a question set link",
      lichsukhaibao: "Declaration history",
      copylink: "Copy the link",
      chonthongtin: "Select information",
      linkbocauhoi: "Link to the question set",
      qrlinkbocauhoi: "QR link to the question set",
      luuqrlinkbocauhoi: "Save the QR link to the question set",
      chonbocauhoi: "Choose a question set",
      timkiemthatbai: "Search failed",
      timkiemthanhcong: "Search success",
      timkiemQR: "Search qr Code",
      tieuchitimQR: "Criteria to find qrCode",
      makhachhang: "Customer&#39;s code",
      chonmakhachhang: "Select customer code",
      khaibaothongtintinnhanthuonghieu: "Declare brand message information",
      cameramattruocmatsau: "Camera: Front/Rear",
      manhinhcheckinmacdinh: "Default checkin screen",
      manhinhkhaibaomacdinh: "Default declaration screen",
      loaigiayin: "Printing paper type: Stamp printing/Cut paper",
      khogiay: "Paper size",
      qrcodebarcodethongtin: "QRcode/ Barcode information",
      tocdoin: "Print speed",
      hinhanhquykhach: "Pictures of customers",
      footertokhai: "Footer Declaration",
      checkininthekhach: "Check in Print guest card",
      thongbaokhibatthuong: "Notify when abnormal",
      nhapfootertokhai: "Enter the declaration footer",
      nhapthongbaokhibatthuong: "Enter notification when abnormal",
      chankytokhai: "Chan signed the declaration",
      nhapchankytokhai: "Enter your foot to sign the declaration",
      hienThiThongTinThem: "Show additional information ",
    },
  },
  vi: {
    ngonNgu: "Tiếng Việt",
    locale: "vi",
    messages: {
      nhapdonvitructhuoc: "Nhập đơn vị trực thuộc",
      donvitructhuoc: "Đơn vị trực thuộc",
      chupanhcanhan: "Chụp ảnh cá nhân",
      vuilongchonphanloaikhach: "Vui lòng chọn Phân loại khách!",
      ngaycheckin: "Ngày check in",
      chonthongtinnguoibenh: "Chọn Thông tin người bệnh",
      nhapvaotukhoa: "Nhập vào từ khóa",
      banchuanhanduocma: "Bạn chưa nhận được mã?",
      vuilongnhapOTP:
        "Quý khách vui lòng nhập mã OTP hệ thống gửi qua tin nhắn SĐT",
      luuyOTP:
        "(Lưu ý: Hệ thống sẽ gửi một tin nhắn OTP vào số điện thoại của bạn)",
      vuilongchoncoquanden: "Vui lòng chọn cơ quan đến!",
      vuilongchonngayden: "Vui lòng chọn ngày đến!",
      nhaptenphieukhaithacthongtin: "Nhập tên phiếu khai thác thông tin",
      front_side: "Mặt trước",
      rear_side: "Mặt sau",
      label_pager: "In tem",
      receipt_pager: "Giấy cắt",
      co: "Có",
      khong: "Không",
      hovaten255: "Họ và tên quá dài, yêu cầu <= 255 ký tự",
      sdtnguoithan: "SĐT người thân",
      khong_ton_tai_bang_hoi:
        "Không tồn tại bộ câu hỏi. Vui lòng liên hệ quản trị hệ thống!",
      in_phieu: "In phiếu",
      the_khach: "THẺ KHÁCH",
      registration_history: "Lịch sử đăng ký",
      update_data: "Sửa thông tin",
      scan_qr_code: "MÃ QRCODE DÙNG ĐỂ CHECK IN , CHECK OUT",
      tim_theo_ma_khach: "TÌM KIẾM THEO MÃ KHÁCH, CMT, SĐT",
      take_photo: "Chụp ảnh",
      your_photo: "ẢNH CỦA BẠN",
      khai_bao_khong_thanh_cong: "Khai báo thông tin không thành công",
      khai_bao_thanh_cong: "Khai báo thông tin thành công",
      vui_long_nhap_so_dien_thoai: "Vui lòng nhập số điện thoại",
      nhapthongtinkhoadieutri: "Nhập thông tin khoa điều trị",
      hoanthanh: "Hoàn thành",
      chuphinh: "Chụp hình",
      ngayden: "Ngày đến",
      khuvucden: "Khu vực đến",
      chonkhuvucden: "Chọn khu vực đến",
      choncoquanden: "Chọn cơ quan đến",
      coquanden: "Cơ quan đến",
      vuilongchonthongtinphiaduoi: "Vui lòng chọn thông tin phía dưới!",
      not_registered_yet: "Chưa đăng ký",
      registered: "Đã đăng ký",
      checked_out: "Đã ra về",
      dungdinhdangngaydangky: "Vui lòng nhập đúng định dạng ngày đăng ký!",
      vuilongnhapngaydangky: "Vui lòng nhập ngày đăng ký!",
      general_information: "Thông tin chung",
      who_are_you: "Quý vị là ai? ",
      register_info: "ĐĂNG KÝ THÔNG TIN KHÁCH HÀNG",
      inphanloaikhach: "In Phân loại khách",
      tenphieukhaithacthongtin: "Tên Phiếu khai thác thông tin",
      saochepthongtin: "Sao chép thông tin",
      vuilongnhapgoiythongtinlienhe: "Vui lòng nhập gợi ý thông tin liên hệ!",
      nhapgoiythongtinlienhe: "Nhập gợi ý thông tin liên hệ",
      goiythongtinlienhe: "Gợi ý thông tin liên hệ",
      timtheothongtinlienquan: "Tìm theo thông tin liên quan",
      thongtinlienquan: "Thông tin liên quan",
      timtheomadoituong: "Tìm theo mã đối tượng",
      timtheotendoituong: "Tìm theo tên đối tượng",
      madoituong: "Mã đối tượng",
      tendoituong: "Tên đối tượng",
      tendoituongtontai: "Tên đối tượng đã tồn tại trên hệ thống",
      madoituongtontai: "Mã đối tượng đã tồn tại trên hệ thống",
      bancomuonxoadoituong: "Bạn có chắc chắn muốn xóa đối tượng",
      xoadoituongthanhcong: "Xóa đối tượng thành công!",
      bandaxoadoituong: "Bạn đã xoá đối tượng",
      thanhcong: "thành công",
      xoadoituongthatbat: "Xóa đối tượng không thành công!",
      themmoidoituongthanhcong: "Thêm mới đối tượng thành công!",
      themmoidoituongthatbai: "Thêm mới đối tượng thất bại!",
      capnhatthongtindoituongthanhcong:
        "Cập nhật thông tin đối tượng thành công!",
      capnhatdoituongthatbai: "Cập nhật đối tượng thất bại!",
      suadoituong: "Sửa đối tượng",
      nhaptendoituong: "Nhập tên đối tượng",
      nhapmadoituong: "Nhập mã đối tượng",
      vuilongnhaptendoituong: "Vui lòng nhập tên đối tượng!",
      vuilongnhapmadoituong: "Vui lòng nhập mã đối tượng!",
      themmoidoituong: "Thêm mới đối tượng",
      capnhatdoituong: "Cập nhật đối tượng",
      quanlydoituong: "Quản lý danh sách đối tượng",
      danhsachdoituong: "Danh sách đối tượng",
      tiengtrung: "Tiếng Trung",
      tienghan: "Tiếng Hàn",
      tiengnhat: "Tiếng Nhật",
      caidat: "Cài đặt",
      ngonngu: "Ngôn ngữ",
      timtheomakhuvuc: "Tìm theo mã khu vực",
      timtheotenkhuvuc: "Tìm theo tên khu vực",
      makhuvuc: "Mã khu vực",
      tenkhuvuc: "Tên khu vực",
      tenkhuvuctontai: "Tên khu vực đã tồn tại trên hệ thống",
      makhuvuctontai: "Mã khu vực đã tồn tại trên hệ thống",
      bancomuonxoakhuvuc: "Bạn có muốn xóa khu vực này?",
      xoakhuvucthanhcong: "Xóa khu vực thành công!",
      xoakhuvucthatbat: "Xóa khu vực không thành công!",
      themmoikhuvucthanhcong: "Thêm mới khu vực thành công!",
      themmoikhuvucthatbai: "Thêm mới khu vực thất bại!",
      capnhatkhuvucthanhcong: "Cập nhật khu vực thành công!",
      capnhatkhuvucthatbai: "Cập nhật khu vực thất bại!",
      suakhuvuc: "Sửa khu vực",
      nhaptenkhuvuc: "Nhập tên khu vực",
      nhapmakhuvuc: "Nhập mã khu vực",
      vuilongnhaptenkhuvuc: "Vui lòng nhập tên khu vực!",
      vuilongnhapmakhuvuc: "Vui lòng nhập mã khu vực!",
      themmoikhuvuc: "Thêm mới khu vực",
      capnhatkhuvuc: "Cập nhật khu vực",
      quanlykhuvuc: "Quản lý danh sách khu vực",
      danhsachkhuvuc: "Danh sách khu vực",
      tendonvitontai: "Tên đơn vị đã tồn tại trên hệ thống",
      madonvitontai: "Mã đơn vị đã tồn tại trên hệ thống",
      error503: "Máy chủ tạm thời ngừng hoạt động",
      bancomuonxoadonvi: "Bạn có muốn xóa đơn vị này?",
      xoadonvithanhcong: "Xóa đơn vị thành công!",
      xoadonvithatbat: "Xóa đơn vị không thành công!",
      themmoidonvithanhcong: "Thêm mới đơn vị thành công!",
      themmoidonvithatbai: "Thêm mới đơn vị thất bại!",
      capnhatdonvithanhcong: "Cập nhật đơn vị thành công!",
      capnhatdonvithatbai: "Cập nhật đơn vị thất bại!",
      suadonvi: "Sửa đơn vị",
      nhaptendonvi: "Nhập tên đơn vị",
      nhapmadonvi: "Nhập mã đơn vị",
      vuilongnhaptendonvi: "Vui lòng nhập tên đơn vị!",
      vuilongnhapmadonvi: "Vui lòng nhập mã đơn vị!",
      themmoidonvi: "Thêm mới đơn vị",
      capnhatdonvi: "Cập nhật đơn vị",
      quanlydonvi: "Quản lý danh sách đơn vị",
      logodonvi: "Logo đơn vị",
      timtheomadonvi: "Tìm theo mã đơn vị",
      timtheotendonvi: "Tìm theo tên đơn vị",
      tendonvi: "Tên đơn vị",
      madonvi: "Mã đơn vị",
      capnhatthietlapthanhcong: "Cập nhật thiết lập thành công!",
      capnhatthietlapthatbai: "Cập nhật thiết lập thất bại!",
      yeucaunhapsdt: "Yêu cầu nhập Số điện thoại",
      manhinhnhapsdt: "Sử dụng Màn hình Nhập SDT",
      yeucaubanghoi: "Yêu cầu Bảng hỏi",
      yeucauchuphinh: "Yêu cầu Chụp hình",
      thietlapchung: "Thiết lập chung",
      danhsachdonvi: "Danh sách đơn vị",
      bancomuonxoacauhoi: "Bạn có muốn câu hỏi này?",
      xoacauhoithanhcong: "Xóa câu hỏi thành công!",
      xoacauhoithatbat: "Xóa câu hỏi không thành công!",
      tuychon: "Tùy chọn",
      bancomuonxoabocauhoi: "Bạn có chắc chắn muốn xoá bộ câu hỏi",
      xoabocauhoithanhcong: "Xóa bộ câu hỏi thành công!",
      xoabocauhoithatbat: "Xóa bộ câu hỏi không thành công!",
      taocauhoi: "Tạo câu hỏi",
      themtuychon: "Thêm tùy chọn",
      macdinh: "Mặc định",
      vuilongnhapnoidungchocotnay: "Vui lòng nhập nội dung cho cột này!",
      vuilongnhapnoidungchohang: "Vui lòng nhập nội dung cho hàng này!",
      cautraloidai: "Câu trả lời dài",
      batbuoc: "Bắt buộc",
      nhieucautraloi: "Nhiều câu trả lời",
      themtuychonkhac: "Thêm tuy chọn Khác",
      vuilongnhaptenchotuychon: "Vui lòng nhập tên cho tùy chọn này!",
      vuilongnhapnoidungoiy: "Vui lòng nhập nội dung gợi ý!",
      goiy: "Gợi ý",
      vuilongnhapnoidungcauhoi: "Vui lòng nhập nội dung câu hỏi!",
      nhapthongtinbocauhoi: "Nhập thông tin bộ câu hỏi",
      vuilongnhapcauhoi: "Nhập tên bộ câu hỏi",
      vuilongnhaptencauhoi: "Vui lòng nhập tên bộ câu hỏi!",
      nhapmacauhoi: "Nhập mã bộ câu hỏi",
      vuilongnhapmacauhoi: "Vui lòng nhập mã bộ câu hỏi!",
      tencauhoi: "Tên câu hỏi",
      macauhoi: "Mã câu hỏi",
      hieuluc: "Hiệu lực",
      chondoituong: "Chọn đối tượng",
      doituong: "Đối tượng",
      timbocauhoi: "Tìm theo bộ câu hỏi",
      mabocauhoi: "Mã bộ câu hỏi",
      tenbocauhoi: "Tên bộ câu hỏi",
      timmabocauhoi: "Tìm theo mã bộ câu hỏi",
      themcot: "Thêm cột",
      themhang: "Thêm hàng",
      binhthuong: "Bình thường",
      cot: "Cột ",
      hang: "Hàng ",
      khac: "Khác",
      batthuong: "BẤT THƯỜNG",
      intokhai: "In tờ khai",
      xemqr: "Xem QRcode",
      quaylai: "Quay lại",
      mattruoc: "Mặt trước",
      matsau: "Mặt sau",
      khaibaoyte: "KHAI BÁO Y TẾ",
      khaibaoytetunguyen: "Khai báo y tế tự nguyện",
      diachi: "Địa chỉ",
      cccd: "CMT/ CCCD",
      thongtinkhachhang: "Thông tin khách hàng",
      chitietkhachdendonvi: "Chi tiết khách đến đơn vị",
      baocaodetailhref: "/bao-cao/chi-tiet",
      timsdt: "Tìm theo số điện thoại",
      timcmt: "Tìm theo số CMT/ CCCD/ Hộ chiếu",
      timmakhach: "Tìm kiếm theo mã khách hàng",
      timtenkhach: "Tìm kiếm theo tên khách hàng",
      chitiet: "Xem chi tiết",
      xoa: "Xóa",
      capnhat: "Cập nhật",
      themmoibocauhoithanhcong: "Thêm mới bộ câu hỏi thành công!",
      themmoibocauhoithatbai: "Thêm mới bộ câu hỏi thất bại!",
      capnhatbocauhoithanhcong: "Cập nhật bộ câu hỏi thành công!",
      capnhatbocauhoithatbai: "Cập nhật bộ câu hỏi thất bại!",
      themmoicauhoithanhcong: "Thêm mới câu hỏi thành công!",
      themmoicauhoithatbai: "Thêm mới câu hỏi thất bại!",
      capnhatcauhoithanhcong: "Cập nhật câu hỏi thành công!",
      capnhatcauhoithatbai: "Cập nhật câu hỏi thất bại!",
      themmoibocauhoi: "Thêm mới bộ câu hỏi",
      capnhapbocauhoi: "Cập nhật bộ câu hỏi",
      khaibaodanhsachcauhoi: "Khai báo danh sách bộ câu hỏi",
      danhsachcauhoi: "Danh sách bộ câu hỏi",
      bocauhoi: "Bộ câu hỏi",
      suabocauhoi: "Sửa bộ câu hỏi",
      sdtnguoibaoho: "SĐT người bảo hộ",
      taikhoantontai: "Tài khoản đã tồn tại",
      timkiemtinhnang: "Tìm kiếm tính năng",
      xayraloi: "Xảy ra lỗi, vui lòng thử lại sau!",
      themmoitaikhoanthatbai: "Thêm mới tài khoản thất bại",
      capnhattaikhoanthatbai: "Cập nhật tài khoản thất bại",
      trong: "trong",
      thaydoimatkhauthatbai: "Bạn đã đổi mật khẩu thất bại!",
      thaydoimatkhauthanhcong: "Bạn đã đổi mật khẩu thành công!",
      khoathatbai: "Bạn đã khóa tài khoản thất bại!",
      mokhoathatbai: "Bạn đã mở khóa tài khoản thất bại!",
      khoathanhcong: "Bạn đã khóa tài khoản thành công!",
      mokhoathanhcong: "Bạn đã mở khóa tài khoản thành công!",
      lammoimatkhauthatbai: "Bạn đã làm mới mật khẩu thất bại",
      lammoimatkhau: "Bạn đã làm mới mật khẩu thành công",
      bancomuonreset: "Bạn có chắc chắn muốn làm mới lại mật khẩu?",
      no: "Hủy",
      oki: "Xác nhận",
      xacnhan: "Xác nhận",
      banmuonmokhoa: "Bạn có chắc chắn muốn mở khóa tài khoản này?",
      banmuonkhoa: "Bạn có chắc chắn muốn khóa tài khoản này?",
      capnhattaikhoanthanhcong: "Cập nhật tài khoản thành công",
      themmoitaikhoanthanhcong: "Tạo mới tài khoản thành công",
      themthanhcong: "Thêm thành công",
      capnhatthanhcong: "Cập nhật thành công",
      capnhatthatbai: "Cập nhật thất bại",
      themmoithatbai: "Thêm mới thất bại",
      thongbaodetail: "Không tìm thấy kết quả phù hợp",
      taikhoanvamatkhau: "Vui lòng nhập tài khoản và mật khẩu!",
      checksdt: "Vui lòng nhập đúng định dạng số điện thoại!",
      checkdate: "Ngày sinh không được lớn hơn ngày hiện tại",
      checkcancuoc: "Vui lòng nhập đúng định dạng số CMT/ CCCD/ Hộ chiếu!",
      xacnhanmatkhau: "Xác nhận mật khẩu",
      matkhaumoi: "Mật khẩu mới",
      nhapmatkhaucu: "Nhập mật khẩu cũ",
      vuilongnhapmatkhaucu: "Vui lòng nhập mật khẩu cũ!",
      nhaplaimatkhaumoi: "Vui lòng nhập lại mật khẩu mới!",
      matkhaucu: "Mật khẩu cũ",
      luu: "Lưu",
      thaydoimatkhau: "Thay đổi mật khẩu",
      xacnhanmatkhaukhongtrung: "Xác nhận mật khẩu mới không trùng khớp!",
      huy: "Hủy",
      taomoi: "Tạo mới",
      luuthaydoi: "Lưu thay đổi",
      matkhau: "Mật khẩu",
      nhapmatkhau: "Nhập mật khẩu",
      themmoitaikhoan: "Thêm mới tài khoản",
      capnhattaikhoan: "Cập nhật thông tin tài khoản",
      checkmatkhau:
        "Mật khẩu yêu cầu tối thiểu 8 ký tự; bao gồm chữ hoa, chữ thường, ký tự đặc biệt và số!",
      vuilongnhapmatkhaumoi: "Vui lòng nhập mật khẩu mới!",
      checktaikhoan:
        "Tên tài khoản trong khoảng 6 - 20 ký tự, không bao gồm dấu cách và ký tự đặc biệt!",
      vuilongnhaptendangnhap: "Vui lòng nhập tên đăng nhập!",
      mokhoataikhoan: "Mở khóa tài khoản",
      khoataikhoan: "Khóa tài khoản",
      resetmatkhau: "Làm mới mật khẩu",
      suataikhoan: "Sửa tài khoản",
      tienich: "Tiện ích",
      ghichu: "Ghi chú",
      nhapghichu: "Nhập ghi chú",
      chonngaydangnhapgannhat: "Chọn ngày đăng nhập gần nhất",
      dangnhapgannhat: "Đăng nhập gần nhất",
      nhapngaytao: "Nhập ngày tạo",
      ngaytao: "Ngày tạo",
      chontrangthai: "Chọn trạng thái",
      trangthai: "Trạng thái",
      chonquyen: "Chọn quyền",
      quyen: "Quyền",
      vuilongchonquyen: "Vui lòng chọn quyền!",
      timtheoten: "Tìm theo tên đăng nhập",
      chonkhuvuc: "Chọn khu vực",
      khuvuc: "Khu vực",
      vuilongchonkhuvuc: "Vui lòng chọn khu vực!",
      tatca: "Tất cả",
      tendangnhap: "Tên đăng nhập",
      nhaptendangnhap: "Nhập tên đăng nhập",
      loctheo: "LỌC THEO",
      themmoi: "Thêm mới",
      toanmanhinh: "Toàn màn hình",
      datlaibocuc: "Đặt lại bố cục",
      catdat: "Cài đặt",
      in: "in",
      doimatkhau: "Đổi mật khẩu",
      dangxuat: "Đăng xuất",
      dangnhap: "Đăng nhập",
      tienganh: "Tiếng Anh",
      tiengviet: "Tiếng Việt",
      tbchuyendoingonngu: "Successful change language!",
      english: "Tiếng Anh - EN",
      vietnam: "Tiếng Việt - VI",
      thaydoingonngu: "Thay đổi ngôn ngữ",
      tieuchichonbaocao: "Chọn tiêu chí in báo cáo",
      baocaothongke: "Báo cáo thống kê Khách đến Đơn vị",
      trangdau: "Trang đầu",
      trangcuoi: "Trang cuối",
      sohanghienthi: "Số hàng hiển thị",
      phanloai: "Phân loại",
      tenkhach: "Tên khách hàng",
      makhach: "Mã khách hàng",
      ngaydanhky: "Ngày đăng ký",
      stt: "STT",
      excel: "Xuất excel",
      xemdanhsach: "Xem Danh sách",
      donvi: "Đơn vị",
      vuilongchondonvi: "Vui lòng chọn đơn vị!",
      chondonvi: "Chọn đơn vị",
      nguoinhanguoibenh: "Người nhà người bệnh",
      nguoibenh: "Người bệnh",
      khach: "Khách",
      checkout: "Khu vực checkout",
      checkin: "Khu vực checkin",
      choncheckout: "Chọn khu vực checkout",
      choncheckin: "Chọn khu vực checkin",
      denngay: "Đến ngày",
      tungay: "Từ ngày",
      danhsachkhachdendonvi: "Danh sách khách đến Đơn vị",
      baocao: "Báo cáo",
      baocaohref: "/bao-cao",
      luuqr: "Lưu mã QR của bạn",
      thongtinqr: "Thông tin QR của bạn",
      vuilongnhapsonha: "Vui lòng nhập đủ Số nhà/ Đường/ Phố...!",
      vuilongchonxa: "Vui lòng chọn Xã/ Phường!",
      vuilongchonhuyen: "Vui lòng chọn Quận/ Huyện!",
      vuilongchonTP: "Vui lòng chọn Tỉnh/ TP!",
      dungdinhdangngay: "Vui lòng nhập đúng định dạng ngày tháng!",
      vuilongnhapngaysinh: "Vui lòng nhập Ngày sinh!",
      vuilongnhaphovaten: "Vui lòng Nhập Họ và tên!",
      quanlytaikhoan: "Quản lý tài khoản",
      danhsachtaikhoan: "Danh sách tài khoản",
      thongtinhanhchinh: "Thông tin hành chính",
      thongtinhanhchinhherf: "/thong-tin-hanh-chinh",
      thongtincanhan: "Thông tin cá nhân",
      hovaten: "Họ và tên",
      nhaphovaten: "Nhập Họ và tên",
      sdt: "Số điện thoại",
      nhapsdt: "Nhập số điện thoại",
      nhapsdtnguoibaoho: "Áp dụng cho người <18 tuổi và người cao tuổi",
      cmt: "Số CMT/ CCCD/ Hộ chiếu",
      nhapcmt: "Nhập số CMT/CCCD/Hộ chiếu",
      ngaysinh: "Ngày sinh",
      nhapngaysinh: "Nhập ngày tháng năm (VD: 310190)",
      nhapngay: "Nhập ngày dạng dd/mm/yyyy",
      gioitinh: "Giới tính",
      chongioitinh: "Chọn giới tính",
      nam: "Nam",
      nu: "Nữ",
      quoctich: "Quốc tịch",
      chonquoctich: "Chọn quốc tịch",
      nghenghiep: "Nghề nghiệp",
      chonnghenghiep: "Chọn nghề nghiệp",
      nguoibaoho: "Người bảo hộ",
      nhaphovatennguoibaoho: "Áp dụng cho người <18 tuổi và người cao tuổi",
      diachivn: "Địa chỉ tại Việt Nam",
      thongTinThem: "Thông tin bổ sung",
      chucVu: "Chức vụ",
      nhapChucVu: "Nhập chức vụ",
      donVi: "Đơn vị",
      nhapDonVi: "Nhập đơn vị",
      soGhe: "Số ghế",
      nhapSoGhe: "Nhập số ghế",
      trinhDoHocVan: "Trình độ học vấn",
      nhapTrinhDoHocVan: "Nhập trình độ học vấn",
      loaiHinh: "Loại hình",
      nhapLoaiHinh: "Nhập loại hình",
      tp: "Tỉnh / Thành phố",
      chontp: "Chọn tỉnh / Thành phố",
      huyen: "Quận / Huyện",
      chonhuyen: "Chọn quận / huyện",
      xa: "Xã / Phường",
      chonxa: "Chọn xã / phường",
      sonha: "Số nhà/Đường/Phố...",
      nhapsonha: "Nhập Số nhà/ Đường/ Phố...",
      qr: "Tạo mã QR",
      lammoi: "Làm mới",
      timkiembenhnhan: "Tìm kiếm bệnh nhân",
      boqua: "Bỏ qua",
      tieptuc: "Tiếp tục",
      maotp: "Vui lòng nhập mã OTP!",
      guilaimaotp: "Gửi lại mã OTP",
      taolinkcheckin: "Tạo link",
      taolinkbocauhoi: "Tạo link bộ câu hỏi",
      lichsukhaibao: "Lịch sử khai báo",
      copylink: "Copy link",
      chonthongtin: "Chọn thông tin",
      linkbocauhoi: "Link bộ câu hỏi",
      qrlinkbocauhoi: "QR link bộ câu hỏi",
      luuqrlinkbocauhoi: "Lưu QR link bộ câu hỏi",
      chonbocauhoi: "Chọn bộ câu hỏi",
      timkiemthatbai: "Tìm kiếm thất bại",
      timkiemthanhcong: "Tìm kiếm thành công",
      timkiemQR: "Tìm kiếm qrCode",
      tieuchitimQR: "Tiêu chí tìm qrCode",
      makhachhang: "Mã Khách hàng",
      chonmakhachhang: "Chọn mã khách hàng",
      khaibaothongtintinnhanthuonghieu:
        "Khai báo thông tin tin nhắn thương hiệu",
      cameramattruocmatsau: "Camera: Mặt trước/ Mặt sau",
      manhinhcheckinmacdinh: "Màn hình checkin mặc định",
      manhinhkhaibaomacdinh: "Màn hình khai báo mặc định",
      loaigiayin: "Loại giấy in: In tem/ Giấy cắt",
      khogiay: "Khổ giấy",
      qrcodebarcodethongtin: "QRcode/ Barcode thông tin",
      tocdoin: "Tốc độ in",
      hinhanhquykhach: "Hình ảnh của quý khách",
      footertokhai: "Footer Tờ khai",
      checkininthekhach: "Check in In thẻ khách",
      thongbaokhibatthuong: "Thông báo khi bất thường",
      nhapfootertokhai: "Nhập footer tờ khai",
      nhapthongbaokhibatthuong: "Nhập thông báo khi bất thường",
      chankytokhai: "Chân ký tờ khai",
      nhapchankytokhai: "Nhập chân ký tờ khai",
      hienThiThongTinThem: "Hiển thị thông tin thêm",
    },
  },
  ko: {
    ngonNgu: "한국어",
    locale: "한국어",
    messages: {
      nhapdonvitructhuoc: "소속부대 입력",
      donvitructhuoc: "소속 단위",
      chupanhcanhan: "개인 사진을 찍으세요",
      vuilongchonphanloaikhach: "게스트 카테고리를 선택해주세요!",
      ngaycheckin: "체크인 날짜",
      chonthongtinnguoibenh: "환자 정보 선택",
      nhapvaotukhoa: "키워드를 입력하세요",
      banchuanhanduocma: "아직 코드를 받지 못하셨나요?",
      vuilongnhapOTP:
        "시스템에서 문자 메시지를 통해 귀하의 전화번호로 보내는 OTP 코드를 입력하세요.",
      luuyOTP: "(참고: 시스템에서 귀하의 전화번호로 OTP 메시지를 보냅니다)",
      vuilongchoncoquanden: "대상기관을 선택해주세요!",
      vuilongchonngayden: "도착일을 선택해주세요!",
      nhaptenphieukhaithacthongtin: "정보 추출 양식의 이름을 입력하세요",
      front_side: "앞쪽",
      rear_side: "뒷면",
      label_pager: "스탬프 인쇄",
      receipt_pager: "종이 컷",
      co: "가지다",
      khong: "그렇지 않다",
      hovaten255: "전체 이름이 너무 깁니다. 255자 미만이어야 합니다.",
      sdtnguoithan: "친척의 전화번호",
      khong_ton_tai_bang_hoi:
        "질문 세트가 존재하지 않습니다. 시스템 관리팀에 문의하세요!",
      in_phieu: "쿠폰 인쇄",
      the_khach: "게스트 카드",
      registration_history: "등록 이력",
      update_data: "정보 편집",
      scan_qr_code: "Qrcode 게스트 코드 스캔",
      tim_theo_ma_khach: "고객코드, 아이디, 전화번호로 검색하세요",
      take_photo: "사진을 찍다",
      your_photo: "사진",
      khai_bao_khong_thanh_cong: "정보 선언 실패",
      khai_bao_thanh_cong: "정보가 성공적으로 선언되었습니다.",
      vui_long_nhap_so_dien_thoai: "전화번호를 입력해주세요",
      nhapthongtinkhoadieutri: "진료과 정보 입력",
      hoanthanh: "완벽한",
      chuphinh: "사진을 찍다",
      ngayden: "그날이 도착한다",
      khuvucden: "도착지역",
      chonkhuvucden: "도착지역 선택",
      choncoquanden: "대상 대리점 선택",
      coquanden: "대행사가 도착합니다.",
      vuilongchonthongtinphiaduoi: "아래 정보를 선택해주세요!",
      not_registered_yet: "미등록",
      registered: "등기",
      checked_out: "떠났다",
      dungdinhdangngaydangky: "올바른 등록 날짜 형식을 입력해주세요!",
      vuilongnhapngaydangky: "등록일자를 입력해주세요!",
      general_information: "일반 정보",
      who_are_you: "누구세요?",
      register_info: "고객 정보 등록",
      inphanloaikhach: "인쇄 손님 분류",
      tenphieukhaithacthongtin: "정보이용양식명",
      saochepthongtin: "정보 복사",
      vuilongnhapgoiythongtinlienhe: "연락처 정보 제안을 입력해 주세요!",
      nhapgoiythongtinlienhe: "추천 연락처 정보 입력",
      goiythongtinlienhe: "추천 연락처 정보",
      timtheothongtinlienquan: "관련정보로 검색",
      thongtinlienquan: "관련 정보",
      timtheomadoituong: "개체 코드로 검색",
      timtheotendoituong: "개체 이름으로 검색",
      madoituong: "개체 코드",
      tendoituong: "개체 이름",
      tendoituongtontai: "개체 이름이 시스템에 이미 존재합니다.",
      madoituongtontai: "개체 코드가 시스템에 이미 존재합니다.",
      bancomuonxoadoituong: "개체를 삭제하시겠습니까?",
      xoadoituongthanhcong: "개체가 성공적으로 삭제되었습니다!",
      bandaxoadoituong: "개체를 삭제했습니다.",
      thanhcong: "성공",
      xoadoituongthatbat: "개체 삭제에 실패했습니다!",
      themmoidoituongthanhcong: "새 개체가 성공적으로 추가되었습니다!",
      themmoidoituongthatbai: "새 개체를 추가하지 못했습니다!",
      capnhatthongtindoituongthanhcong:
        "개체 정보가 성공적으로 업데이트되었습니다!",
      capnhatdoituongthatbai: "개체 업데이트에 실패했습니다!",
      suadoituong: "개체 편집",
      nhaptendoituong: "개체 이름을 입력하세요.",
      nhapmadoituong: "개체 코드를 입력하세요.",
      vuilongnhaptendoituong: "개체 이름을 입력하세요!",
      vuilongnhapmadoituong: "개체코드를 입력해주세요!",
      themmoidoituong: "새 개체 추가",
      capnhatdoituong: "객체 업데이트",
      quanlydoituong: "개체 목록 관리",
      danhsachdoituong: "객체 목록",
      tiengtrung: "중국인",
      tienghan: "한국인",
      tiengnhat: "일본어",
      caidat: "환경",
      ngonngu: "언어",
      timtheomakhuvuc: "지역번호로 검색",
      timtheotenkhuvuc: "지역명으로 검색",
      makhuvuc: "지역 코드",
      tenkhuvuc: "지역명",
      tenkhuvuctontai: "지역 이름이 시스템에 이미 존재합니다.",
      makhuvuctontai: "지역번호가 시스템에 이미 존재합니다.",
      bancomuonxoakhuvuc: "이 영역을 삭제하시겠습니까?",
      xoakhuvucthanhcong: "영역 삭제가 완료되었습니다.",
      xoakhuvucthatbat: "지역 삭제에 실패했습니다!",
      themmoikhuvucthanhcong: "새로운 지역을 성공적으로 추가했습니다!",
      themmoikhuvucthatbai: "새로운 실패 영역이 추가되었습니다!",
      capnhatkhuvucthanhcong: "지역 업데이트가 완료되었습니다!",
      capnhatkhuvucthatbai: "지역 업데이트에 실패했습니다!",
      suakhuvuc: "편집 영역",
      nhaptenkhuvuc: "지역 이름을 입력하세요",
      nhapmakhuvuc: "지역번호를 입력하세요",
      vuilongnhaptenkhuvuc: "지역명을 입력해주세요!",
      vuilongnhapmakhuvuc: "지역번호를 입력해주세요!",
      themmoikhuvuc: "새 지역 추가",
      capnhatkhuvuc: "업데이트 영역",
      quanlykhuvuc: "지역 목록 관리",
      danhsachkhuvuc: "지역 목록",
      tendonvitontai: "장치 이름이 이미 시스템에 존재합니다.",
      madonvitontai: "단위 코드가 시스템에 이미 존재합니다.",
      error503: "서버가 일시적으로 다운되었습니다",
      bancomuonxoadonvi: "이 장치를 삭제하시겠습니까?",
      xoadonvithanhcong: "장치를 삭제했습니다!",
      xoadonvithatbat: "장치 삭제에 실패했습니다!",
      themmoidonvithanhcong: "새로운 유닛이 성공적으로 추가되었습니다!",
      themmoidonvithatbai: "새 장치를 추가하지 못했습니다!",
      capnhatdonvithanhcong: "유닛 업데이트 성공!",
      capnhatdonvithatbai: "장치 업데이트에 실패했습니다!",
      suadonvi: "단위 편집",
      nhaptendonvi: "유닛 이름을 입력하세요",
      nhapmadonvi: "단위코드를 입력하세요",
      vuilongnhaptendonvi: "유닛명을 입력해주세요!",
      vuilongnhapmadonvi: "단위코드를 입력해주세요!",
      themmoidonvi: "새 단위 추가",
      capnhatdonvi: "단위 업데이트",
      quanlydonvi: "단위 목록 관리",
      logodonvi: "유닛 로고",
      timtheomadonvi: "단위코드로 검색",
      timtheotendonvi: "유닛 이름으로 검색",
      tendonvi: "단위명",
      madonvi: "단위 코드",
      capnhatthietlapthanhcong: "설정을 업데이트했습니다.",
      capnhatthietlapthatbai: "설정 업데이트에 실패했습니다!",
      yeucaunhapsdt: "전화번호를 입력해야 합니다.",
      manhinhnhapsdt: "SDT 입력 화면 사용",
      yeucaubanghoi: "설문지 요청",
      yeucauchuphinh: "사진 요청",
      thietlapchung: "일반 설정",
      danhsachdonvi: "단 목록",
      bancomuonxoacauhoi: "이 질문을 원하시나요?",
      xoacauhoithanhcong: "질문이 삭제되었습니다!",
      xoacauhoithatbat: "질문 삭제에 실패했습니다!",
      tuychon: "옵션",
      bancomuonxoabocauhoi: "질문 세트를 삭제하시겠습니까?",
      xoabocauhoithanhcong: "질문 세트를 삭제했습니다.",
      xoabocauhoithatbat: "질문 세트 삭제에 실패했습니다!",
      taocauhoi: "질문 만들기",
      themtuychon: "추가 옵션",
      macdinh: "기본",
      vuilongnhapnoidungchocotnay: "이 열의 내용을 입력하세요!",
      vuilongnhapnoidungchohang: "이 행의 내용을 입력하세요!",
      cautraloidai: "긴 답변",
      batbuoc: "의무사항",
      nhieucautraloi: "많은 답변",
      themtuychonkhac: "기타 옵션 추가",
      vuilongnhaptenchotuychon: "이 옵션의 이름을 입력하세요!",
      vuilongnhapnoidungoiy: "추천 콘텐츠를 입력해주세요!",
      goiy: "제안하다",
      vuilongnhapnoidungcauhoi: "질문 내용을 입력해주세요!",
      nhapthongtinbocauhoi: "문제 세트 정보 입력",
      vuilongnhapcauhoi: "질문 세트 이름을 입력하세요.",
      vuilongnhaptencauhoi: "문제 세트 이름을 입력해주세요!",
      nhapmacauhoi: "문제 세트 코드를 입력하세요",
      vuilongnhapmacauhoi: "문제 세트 코드를 입력해주세요!",
      tencauhoi: "질문 이름",
      macauhoi: "질문 코드",
      hieuluc: "효과",
      chondoituong: "개체 선택",
      doituong: "물체",
      timbocauhoi: "질문 세트로 검색",
      mabocauhoi: "문제 세트 코드",
      tenbocauhoi: "질문 세트 이름",
      timmabocauhoi: "문제 세트 코드로 검색",
      themcot: "더 많은 콜럼",
      themhang: "상품 추가",
      binhthuong: "정상",
      cot: "열",
      hang: "열",
      khac: "다른",
      batthuong: "이상",
      intokhai: "선언문을 인쇄하세요",
      xemqr: "QR코드 보기",
      quaylai: "돌아와",
      mattruoc: "앞쪽",
      matsau: "뒷면",
      khaibaoyte: "건강 선언",
      khaibaoytetunguyen: "자발적 의료 선언",
      diachi: "주소",
      cccd: "CMT/CCCD",
      thongtinkhachhang: "고객 정보",
      chitietkhachdendonvi: "해당 유닛 방문자의 세부 정보",
      baocaodetailhref: "/how-high/chi-tiet",
      timsdt: "전화번호로 검색",
      timcmt: "ID/CCCD/여권번호로 검색",
      timmakhach: "고객 코드로 검색",
      timtenkhach: "고객 이름으로 검색",
      chitiet: "자세히보다",
      xoa: "삭제",
      capnhat: "업데이트",
      themmoibocauhoithanhcong: "새로운 질문 세트가 성공적으로 추가되었습니다!",
      themmoibocauhoithatbai: "새로운 실패한 질문 세트를 추가했습니다!",
      capnhatbocauhoithanhcong: "질문 세트가 성공적으로 업데이트되었습니다!",
      capnhatbocauhoithatbai: "질문 세트 업데이트에 실패했습니다!",
      themmoicauhoithanhcong: "새 질문이 성공적으로 추가되었습니다!",
      themmoicauhoithatbai: "새로운 실패한 질문을 추가하세요!",
      capnhatcauhoithanhcong: "질문이 성공적으로 업데이트되었습니다!",
      capnhatcauhoithatbai: "질문 업데이트에 실패했습니다.",
      themmoibocauhoi: "새로운 질문 세트 추가",
      capnhapbocauhoi: "질문 세트 업데이트",
      khaibaodanhsachcauhoi: "질문 세트 목록 선언",
      danhsachcauhoi: "질문 세트 목록",
      bocauhoi: "질문 세트",
      suabocauhoi: "질문 세트 수정",
      sdtnguoibaoho: "보호자의 전화번호",
      taikhoantontai: "계정이 이미 존재합니다.",
      timkiemtinhnang: "검색 기능",
      xayraloi: "오류가 발생했습니다. 잠시 후 다시 시도 해주세요!",
      themmoitaikhoanthatbai: "새 계정을 추가하지 못했습니다.",
      capnhattaikhoanthatbai: "계정 업데이트 실패",
      trong: "~에",
      thaydoimatkhauthatbai: "비밀번호를 변경하지 못했습니다!",
      thaydoimatkhauthanhcong: "비밀번호가 성공적으로 변경되었습니다!",
      khoathatbai: "귀하의 계정이 실패했습니다!",
      mokhoathatbai: "계정 잠금 해제에 실패했습니다!",
      khoathanhcong: "귀하의 계정이 성공적으로 잠겼습니다!",
      mokhoathanhcong: "귀하의 계정이 성공적으로 잠금 해제되었습니다!",
      lammoimatkhauthatbai: "비밀번호를 새로 고치지 못했습니다.",
      lammoimatkhau: "비밀번호가 성공적으로 갱신되었습니다",
      bancomuonreset: "비밀번호를 재설정하시겠습니까?",
      no: "취소",
      oki: "확인하다",
      xacnhan: "확인하다",
      banmuonmokhoa: "이 계정을 잠금 해제하시겠습니까?",
      banmuonkhoa: "이 계정을 차단하시겠습니까?",
      capnhattaikhoanthanhcong: "계정이 업데이트되었습니다.",
      themmoitaikhoanthanhcong: "새 계정이 성공적으로 생성되었습니다.",
      themthanhcong: "더 많은 성공",
      capnhatthanhcong: "업데이트 성공",
      capnhatthatbai: "업데이트가 실패",
      themmoithatbai: "새로 추가하지 못했습니다.",
      thongbaodetail: "일치하는 결과가 없습니다",
      taikhoanvamatkhau: "계정과 비밀번호를 입력해주세요!",
      checksdt: "올바른 전화번호 형식을 입력해주세요!",
      checkdate: "생년월일은 현재 날짜보다 이후일 수 없습니다.",
      checkcancuoc: "올바른 ID/CCCD/여권 번호 형식을 입력하세요!",
      xacnhanmatkhau: "비밀번호 확인",
      matkhaumoi: "새 비밀번호",
      nhapmatkhaucu: "이전 비밀번호를 입력하세요",
      vuilongnhapmatkhaucu: "이전 비밀번호를 입력해주세요!",
      nhaplaimatkhaumoi: "새로운 비밀번호를 다시 입력해주세요!",
      matkhaucu: "기존 비번호",
      luu: "구하다",
      thaydoimatkhau: "비밀번호 변경",
      xacnhanmatkhaukhongtrung: "새 비밀번호가 일치하지 않는지 확인하세요!",
      huy: "취소",
      taomoi: "새로 만들기",
      luuthaydoi: "변경 사항을 저장하다",
      matkhau: "비밀번호",
      nhapmatkhau: "암호 입력",
      themmoitaikhoan: "새 계정 추가",
      capnhattaikhoan: "계정 정보 업데이트",
      checkmatkhau:
        "비밀번호는 최소 8자 이상이 필요합니다. 대문자, 소문자, 특수문자, 숫자가 포함됩니다!",
      vuilongnhapmatkhaumoi: "새로운 비밀번호를 입력해주세요!",
      checktaikhoan: "공백, 특수문자를 제외한 6~20자 이내의 계정명!",
      vuilongnhaptendangnhap: "사용자 이름을 입력해주세요!",
      mokhoataikhoan: "계정 잠금 해제",
      khoataikhoan: "계정 잠금",
      resetmatkhau: "비밀번호 새로고침",
      suataikhoan: "계정 수정",
      tienich: "유용",
      ghichu: "메모",
      nhapghichu: "메모 입력",
      chonngaydangnhapgannhat: "가장 최근 로그인 날짜를 선택하세요.",
      dangnhapgannhat: "최근 로그인",
      nhapngaytao: "생성일을 입력하세요.",
      ngaytao: "생성 일자",
      chontrangthai: "상태 선택",
      trangthai: "상태",
      chonquyen: "권한 선택",
      quyen: "허가",
      vuilongchonquyen: "오른쪽을 선택해주세요!",
      timtheoten: "사용자 이름으로 검색",
      chonkhuvuc: "지역 선택",
      khuvuc: "영역",
      vuilongchonkhuvuc: "지역을 선택해주세요!",
      tatca: "모두",
      tendangnhap: "사용자 이름",
      nhaptendangnhap: "로그인 이름을 입력하세요",
      loctheo: "필터링 기준",
      themmoi: "새로운 걸 더하다",
      toanmanhinh: "전체 화면",
      datlaibocuc: "레이아웃 재설정",
      catdat: "환경",
      in: "인쇄",
      doimatkhau: "비밀번호 변경",
      dangxuat: "로그 아웃",
      dangnhap: "로그인",
      tienganh: "영어",
      tiengviet: "베트남어",
      tbchuyendoingonngu: "언어가 성공적으로 변경되었습니다!",
      english: "영어 - EN",
      vietnam: "베트남어 - VI",
      thaydoingonngu: "언어 변경",
      tieuchichonbaocao: "보고서 인쇄 기준 선택",
      baocaothongke: "단위 방문자의 통계 보고서",
      trangdau: "첫 페이지",
      trangcuoi: "마지막 페이지",
      sohanghienthi: "표시되는 행 수",
      phanloai: "나누다",
      tenkhach: "고객 이름",
      makhach: "고객의 코드",
      ngaydanhky: "등록 날짜",
      stt: "아니요",
      excel: "엑셀 내보내기",
      xemdanhsach: "목록 보기",
      donvi: "단위",
      vuilongchondonvi: "단위를 선택해주세요!",
      chondonvi: "단위 선택",
      nguoinhanguoibenh: "환자의 가족",
      nguoibenh: "인내심 있는",
      khach: "손님",
      checkout: "계산대",
      checkin: "체크인 장소",
      choncheckout: "결제 지역을 선택하세요",
      choncheckin: "체크인 지역 선택",
      denngay: "현재까지",
      tungay: "부터",
      danhsachkhachdendonvi: "부대 방문자 목록",
      baocao: "보고서",
      baocaohref: "/bao-cao",
      luuqr: "QR 코드 저장",
      thongtinqr: "귀하의 QR 정보",
      vuilongnhapsonha: "전체 집 번호/거리/거리를 입력하세요...!",
      vuilongchonxa: "코뮌/와드를 선택해주세요!",
      vuilongchonhuyen: "지역을 선택해주세요!",
      vuilongchonTP: "시/도를 선택해주세요!",
      dungdinhdangngay: "정확한 날짜 형식을 입력해주세요!",
      vuilongnhapngaysinh: "생년월일을 입력해주세요!",
      vuilongnhaphovaten: "전체 이름을 입력하세요!",
      quanlytaikhoan: "계정 관리",
      danhsachtaikhoan: "계정 목록",
      thongtinhanhchinh: "행정정보",
      thongtinhanhchinhherf: "/thong-tin-hanh-chinh",
      thongtincanhan: "개인 정보",
      hovaten: "이름과 성",
      nhaphovaten: "이름과 성을 입력하세요.",
      sdt: "전 번호",
      nhapsdt: "전화번호를 입력하세요",
      nhapsdtnguoibaoho: "18세 미만 및 노인에게 적용됩니다.",
      cmt: "ID/CCCD/여권번호",
      nhapcmt: "ID/CCCD/여권번호를 입력하세요.",
      ngaysinh: "생일",
      nhapngaysinh: "날짜, 월, 연도를 입력하세요. (예: 310190)",
      nhapngay: "dd/mm/yyyy 형식으로 날짜를 입력하세요.",
      gioitinh: "섹스",
      chongioitinh: "성별을 선택하세요",
      nam: "남성",
      nu: "여성",
      quoctich: "국적",
      chonquoctich: "국적을 선택하세요",
      nghenghiep: "직업",
      chonnghenghiep: "직업을 선택하세요",
      nguoibaoho: "보호자",
      nhaphovatennguoibaoho: "18세 미만 및 노인에게 적용됩니다.",
      diachivn: "베트남 주소",
      thongTinThem: "Additional information",
      chucVu: "Position",
      nhapChucVu: "Enter position",
      donVi: "Entity",
      nhapDonVi: "Enter entity",
      soGhe: "seat number",
      nhapSoGhe: "Enter seat number",
      trinhDoHocVan: "Education level",
      nhapTrinhDoHocVan: "Education level",
      loaiHinh: "Category",
      nhapLoaiHinh: "Enter category",
      tp: "주/시",
      chontp: "주/시 선택",
      huyen: "구역",
      chonhuyen: "구역/구역 선택",
      xa: "병동",
      chonxa: "코뮌/구 선택",
      sonha: "집 번호/도로/거리...",
      nhapsonha: "집 번호/거리/거리 입력...",
      qr: "QR 코드 생성",
      lammoi: "새로 고치다",
      timkiembenhnhan: "환자 검색",
      boqua: "건너뛰다",
      tieptuc: "계속하다",
      maotp: "OTP코드를 입력해주세요!",
      guilaimaotp: "OTP 코드 재전송",
      taolinkcheckin: "링크 만들기",
      taolinkbocauhoi: "질문 세트 링크 만들기",
      lichsukhaibao: "선언 내역",
      copylink: "링크 복사",
      chonthongtin: "보를 선택하세요",
      linkbocauhoi: "질문 세트에 대한 링크",
      qrlinkbocauhoi: "질문 세트에 대한 QR 링크",
      luuqrlinkbocauhoi: "질문 세트에 QR 링크를 저장하세요.",
      chonbocauhoi: "질문 세트를 선택하세요",
      timkiemthatbai: "검색 실패",
      timkiemthanhcong: "검색 성공",
      timkiemQR: "QR 코드 검색",
      tieuchitimQR: "qrCode를 찾는 기준",
      makhachhang: "고객의 코드",
      chonmakhachhang: "고객 코드 선택",
      khaibaothongtintinnhanthuonghieu: "브랜드 메시지 정보 선언",
      cameramattruocmatsau: "카메라: 전면/후면",
      manhinhcheckinmacdinh: "기본 체크인 화면",
      manhinhkhaibaomacdinh: "기본 선언 화면",
      loaigiayin: "인쇄 용지 종류: 스탬프 인쇄/절단 용지",
      khogiay: "용지 크기",
      qrcodebarcodethongtin: "QR코드/바코드 정보",
      tocdoin: "인쇄 속도",
      hinhanhquykhach: "고객 사진",
      footertokhai: "바닥글 선언",
      checkininthekhach: "체크인 투숙객 카드 인쇄",
      thongbaokhibatthuong: "이상시 알림",
      nhapfootertokhai: "선언문 바닥글을 입력하세요.",
      nhapthongbaokhibatthuong: "이상시 알림입력",
      chankytokhai: "찬이 선언서에 서명했다",
      nhapchankytokhai: "선언문에 서명하려면 발을 입력하세요.",
      hienThiThongTinThem: "Show additional information ",
    },
  },
};
