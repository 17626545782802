import styled from "styled-components";

const HeaderContainer = styled("div")`
  height: 70px;
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  background: white;
  button {
    border: none;
    background: white;
    box-shadow: none;
  }
  .left {
    display: flex;
    align-items: center;
    text-align: center;
    margin-left: 25px;
    button {
      span {
        color: #fed603;
        font-size: 22px;
      }
    }
  }
  .right {
  }
`;

export { HeaderContainer };
